<template>
  <el-dialog
    :show-close="false"
    :visible="repaymentShow"
    title="新建还款交易"
    width="900px"
    @open="loading"
  >
    <Divider :title="'还款信息'" style="margin: 0"></Divider>
    <el-form
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="110px"
    >
      <el-row>
        <el-col :span="11">
          <el-form-item label="收款账户" prop="payeeAccount">
            <el-input disabled :value="ruleForm.payeeAccountName + '-' + ruleForm.payeeAccount"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="11">
          <el-form-item label="付款账户" prop="projectCode">
            <el-select
              filterable
              reserve-keyword
              remote
              v-model="ruleForm.projectCode"
              placeholder="请选择项目"
              style="width: 100%"
              :remote-method="getTemplate"
              @change="platformChange()"
            >
              <el-option
                v-for="item in projectList"
                :key="item.code"
                :label="item.name + '-' + item.subAccount"
                :value="item.code"
              ></el-option>
            </el-select>
            <span style="color: blue" @click="getBalance()">查询余额</span>
            <span v-if="total" style="margin-left:10px">{{ total }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="11" :push="1">
          <el-form-item label="金额" prop="money">
            <el-input-number
              v-model="ruleForm.money"
              :min="0"
              :precision="2"
              label="请输入金额"
              style="width: 100%"
            ></el-input-number>
          </el-form-item>
          <span style="color: blue" @click="getBalance()">查询还款余额</span>
          <span v-if="total" style="margin-left:10px">{{ repaymentTotal }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="ruleForm.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <Divider :title="'审批流程'" :tag="`如需变更审批人，请在项目管理中进行操作`" style="margin: 0"></Divider>
      <el-row style="width: 100%" v-for="(item, i) in checkList" :key="i">
        <el-col :span="12">
          <el-form-item :label="item.name + (i + 1)" required style="width: 100%">
            <SelectUser :isChecker="false" :type="typeNew" v-model="item.value" :key="'k' + i"></SelectUser>
          </el-form-item>
        </el-col>
      </el-row>
      <Divider :title="'复核操作人员'" :tag="`如需变更复核人，请在项目管理中进行操作`" style="margin: 0"></Divider>
      <el-row style="width: 100%">
        <el-col :span="12">
          <el-form-item label="复核人" required style="width: 100%">
            <SelectUser :type="typeNew" :key="'ysr'" v-model="checkerCode.value"></SelectUser>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog('ruleForm')">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { api } from '../../api/base/index';
export const enterpriseAccountInfo = api()('enterprise.account.info.json');
export const projectList = api()('project.list.json');
export const subAccountBalance = api()('subAccount.balance.json');
export const projectCheckCreate = api()('project.check.create.json');
export default {
  components: {
    Divider: () => import('../../components/divider.vue'),
    SelectUser: () => import('../task/selectUser.vue'),
  },
  data () {
    return {
      total: '***',
      ruleForm: {
        projectCode: '',
        money: '',
        remark: '',
        payAccount: '',
        payAccountName: '',
        type: 'payBack',
        payeeAccount: '',
        payeeAccountName: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入工资包名称', trigger: 'blur' },
        ],

        projectCode: [
          { required: true, message: '请选择归属项目', trigger: 'blur' },
        ],
        money: [{ required: true, message: '请输入金额', trigger: 'blur' }],
        remark: [],
      },
      repaymentTotal: '',
      typeNew: '工资包',
      checkList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],
      checkerCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },
      projectList: [
      ],
    };
  },
  props: {
    code: String,
    repaymentShow: Boolean
  },

  methods: {
    async getTemplate (keywords) {
      const params = {
        keywords: keywords,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      projectList(params).then((res) => {

        this.projectList = res.list;
      });
    },
    async getBalance () {
      if (this.ruleForm.projectCode) {
        await subAccountBalance({ code: this.ruleForm.projectCode }).then(
          (res) => {
            this.total = res

          }
        );
      } else {
        this.$message({
          message: '请先选择项目付款账户',
          type: 'warning'
        });
      }
    },
    async loading () {
      await enterpriseAccountInfo({
        code: window.localStorage.getItem('enterprise'),
      }).then((res) => {

        this.ruleForm.payeeAccount = res.shadowAcctNo;
        this.ruleForm.payeeAccountName = res.accountName;
      });
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      await projectList(params).then((res) => {

        this.projectList = res.list;
      });

    },
    platformChange () {
      this.total = '***'
      console.log(this.ruleForm.money);
      const rs = this.projectList.filter(
        (it) => it.code === this.ruleForm.projectCode
      )[0];
      this.ruleForm.payAccount = rs.subAccount;
      this.ruleForm.payAccountName = rs.name;
      let _checkList = [];
      rs.payBackViewers.split(',').forEach((it) => {
        _checkList.push({
          name: '审批人',
          value: {
            subjectAccount: it,
          },
        });
      });
      this.checkList = _checkList;
      this.checkerCode.value.subjectAccount = rs.payBackReviewer;

    },
    close (formName) {
      this.total = '***'
      this.$refs[formName].resetFields();
      this.$emit('update:repaymentShow', false);
      this.$emit('close');
    },
    closeDialog (formName) {

      this.close(formName);
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.ruleForm.money = this.ruleForm.money.toString();
          projectCheckCreate({ ...this.ruleForm }).then((res) => {
            console.log(res);

            this.close(formName);
          })
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
.el-dialog__body {
  max-height: 500px;

  overflow: auto;
  padding: 10px 40px;
}
</style>